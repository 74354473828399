import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Grid, Box, Typography, MenuItem, Divider, FormControl, InputLabel, Select } from '@mui/material';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const MAX_AREAS = 6;
const AREA_COLORS = ['red', 'blue', 'green', 'purple', 'orange', 'cyan'];
const API_PATH = process.env.REACT_APP_API_PATH;

const RichMenuForm = () => {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [richMenuId, setRichMenuId] = useState('');
  const [richMenus, setRichMenus] = useState([]);
  const [size, setSize] = useState({ width: 2500, height: 1686 });
  const [name, setName] = useState('');
  const [chatBarText, setChatBarText] = useState('');
  const [areas, setAreas] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loadedImage, setLoadedImage] = useState(null);
  const [selectedRichMenuId, setSelectedRichMenuId] = useState(null);
  const canvasRef = useRef(null);
  const SCALE_FACTOR = 0.35;

  const columns = [
    { field: 'richMenuId', headerName: 'RichMenu ID', flex: 1 },
    { field: 'name', headerName: '名稱', flex: 1 },
    { field: 'chatBarText', headerName: 'Chat Bar Text', flex: 1 },
    {
      field: 'selected',
      headerName: '使用中',
      flex: 1,
      renderCell: (params) => (params.value ? <Typography color="primary">是</Typography> : '否')
    },
    {
      field: 'actions',
      headerName: '操作',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" color="secondary" onClick={() => handleDelete(params.row.richMenuId)}>
          刪除
        </Button>
      )
    }
  ];

  // 定義最小寬度和高度
  const MIN_WIDTH = 20;
  const MIN_HEIGHT = 20;

  // 繪製區域
  const drawAreas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // 繪製圖片
    if (loadedImage) {
      ctx.drawImage(loadedImage, 0, 0, size.width * SCALE_FACTOR, size.height * SCALE_FACTOR);
    }

    // 繪製已選區域
    areas.forEach((area, index) => {
      ctx.strokeStyle = AREA_COLORS[index % AREA_COLORS.length];
      ctx.lineWidth = 2;
      ctx.strokeRect(
        area.x * SCALE_FACTOR,
        area.y * SCALE_FACTOR,
        area.width * SCALE_FACTOR,
        area.height * SCALE_FACTOR
      );
      ctx.font = '20px Arial';
      ctx.fillStyle = AREA_COLORS[index % AREA_COLORS.length];
      ctx.fillText(String.fromCharCode(97 + index), area.x * SCALE_FACTOR + 5, area.y * SCALE_FACTOR + 25);
    });

    // 繪製即時框選區域
    if (isDrawing) {
      const x = Math.min(startPosition.x, currentPosition.x);
      const y = Math.min(startPosition.y, currentPosition.y);
      const width = Math.abs(currentPosition.x - startPosition.x);
      const height = Math.abs(currentPosition.y - startPosition.y);

      ctx.strokeStyle = 'blue';
      ctx.lineWidth = 2;
      ctx.strokeRect(x * SCALE_FACTOR, y * SCALE_FACTOR, width * SCALE_FACTOR, height * SCALE_FACTOR);

      // 半透明填充
      ctx.fillStyle = 'rgba(0, 0, 255, 0.2)';
      ctx.fillRect(x * SCALE_FACTOR, y * SCALE_FACTOR, width * SCALE_FACTOR, height * SCALE_FACTOR);

      // 顯示區域尺寸
      ctx.fillStyle = 'black';
      ctx.font = '16px Arial';
      ctx.fillText(
        `W: ${Math.round(width)} H: ${Math.round(height)}`,
        x * SCALE_FACTOR + 5,
        y * SCALE_FACTOR + 20
      );
    }
  };

  // 判斷是否有區域重疊
  const isAreaOverlapping = (newArea) => {
    return areas.some((area) => {
      return !(
        newArea.x + newArea.width <= area.x ||
        newArea.x >= area.x + area.width ||
        newArea.y + newArea.height <= area.y ||
        newArea.y >= area.y + area.height
      );
    });
  };

  // 當點擊畫布開始框選
  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setStartPosition({
      x: (e.clientX - rect.left) / SCALE_FACTOR,
      y: (e.clientY - rect.top) / SCALE_FACTOR
    });
    setIsDrawing(true);
  };

  // 當滑鼠移動時更新框選的目前位置
  const updateCurrentPosition = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setCurrentPosition({
      x: (e.clientX - rect.left) / SCALE_FACTOR,
      y: (e.clientY - rect.top) / SCALE_FACTOR
    });
  };

  // 完成框選並保存區域資料
  const finishDrawing = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const endPosition = {
      x: (e.clientX - rect.left) / SCALE_FACTOR,
      y: (e.clientY - rect.top) / SCALE_FACTOR
    };

    // 計算左上角座標和寬高
    const x = Math.min(startPosition.x, endPosition.x);
    const y = Math.min(startPosition.y, endPosition.y);
    const width = Math.abs(endPosition.x - startPosition.x);
    const height = Math.abs(endPosition.y - startPosition.y);
    // 檢查XY是否為負數
    if (x < 0 || y < 0) {
      alert('區域無效，不能在畫布外框選');
      setIsDrawing(false);
      return;
    }
    // 檢查區域是否過小
    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
      alert(`區域太小，最小寬度為 ${MIN_WIDTH}px，最小高度為 ${MIN_HEIGHT}px`);
      setIsDrawing(false);
      return;
    }

    const newArea = {
      id: uuidv4(),
      x: x,
      y: y,
      width: width,
      height: height,
      action: {
        type: 'message'
      } // 預設 action
    };

    // 檢查是否超過最大數量
    if (areas.length >= MAX_AREAS) {
      alert(`最多只能框選 ${MAX_AREAS} 個區域`);
      setIsDrawing(false);
      return;
    }

    // 檢查區域是否重疊
    if (isAreaOverlapping(newArea)) {
      alert('區域重疊，請重新框選');
      setIsDrawing(false);
      return;
    }

    // 保存新的區域
    setAreas([...areas, newArea]);

    // 重置狀態
    setStartPosition({ x: 0, y: 0 });
    setCurrentPosition({ x: 0, y: 0 });
    setIsDrawing(false);
  };

  // 處理 Area 的屬性變更
  const handleAreaChange = (index, field, value) => {
    const newAreas = [...areas];
    const area = { ...newAreas[index] };

    // 檢查 field 是否為嵌套屬性
    if (field.startsWith('action.')) {
      const actionField = field.split('.')[1];
      const action = { ...area.action, [actionField]: value };
      area.action = action;
    } else {
      area[field] = value;
    }

    // 確保 x, y, width, height 為數字
    ['x', 'y', 'width', 'height'].forEach((key) => {
      if (area[key] !== undefined) {
        area[key] = parseFloat(area[key]) || 0;
      }
    });

    newAreas[index] = area;

    // 檢查重疊
    const currentArea = area;
    const otherAreas = newAreas.filter((_, i) => i !== index);

    const isOverlapping = otherAreas.some((otherArea) => {
      return !(
        currentArea.x + currentArea.width <= otherArea.x ||
        currentArea.x >= otherArea.x + otherArea.width ||
        currentArea.y + currentArea.height <= otherArea.y ||
        currentArea.y >= otherArea.y + otherArea.height
      );
    });

    if (isOverlapping) {
      alert('區域重疊，請調整區域屬性');
      return;
    }

    setAreas(newAreas);
  };

  // 圖片上傳並預覽
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    // 檢查檔案格式，只允許 png 和 jpeg 格式
    const allowedTypes = ['image/png', 'image/jpeg'];
    if (!allowedTypes.includes(file.type)) {
      alert('圖片格式錯誤，只能上傳 PNG 或 JPEG 格式的圖片');
      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onloadend = () => {
      img.src = reader.result;
      img.onload = () => {
        // 檢查圖片尺寸，要求是 2500x1686
        if (img.width !== 2500 || img.height !== 1686) {
          alert('圖片尺寸必須為 2500x1686');
          return;
        }

        // 圖片格式和尺寸都正確時，儲存圖片資料
        setLoadedImage(img); // 加載完成後保存圖片
        setImagePreview(reader.result); // 儲存 base64 以便預覽
        setImageFile(file);
      };
    };

    reader.readAsDataURL(file);
  };

  // 驗證 Rich Menu
  const handleValidateRichMenu = async () => {
    const mappedAreas = areas.map((area) => {
      const actionType = area.action.type;
      const action = { Type: actionType };

      if (actionType === 'message') {
        action.Label = area.action.label || '';
        action.Text = area.action.text || '';
      } else if (actionType === 'postback') {
        action.Label = area.action.label || '';
        action.Data = area.action.data || '';
        if (area.action.displayText) {
          action.DisplayText = area.action.displayText;
        }
        if (area.action.inputOption) {
          action.InputOption = area.action.inputOption;
        }
        if (area.action.fillInText) {
          action.FillInText = area.action.fillInText;
        }
      } else if (actionType === 'uri') {
        action.Label = area.action.label || '';
        action.Uri = area.action.uri || '';
        if (area.action.altUriDesktop) {
          action.AltUriDesktop = area.action.altUriDesktop;
        }
      } else if (actionType === 'datetimepicker') {
        action.Label = area.action.label || '';
        action.Data = area.action.data || '';
        action.Mode = area.action.mode || '';
        if (area.action.initial) {
          action.Initial = area.action.initial;
        }
        if (area.action.max) {
          action.Max = area.action.max;
        }
        if (area.action.min) {
          action.Min = area.action.min;
        }
      }

      return {
        Bounds: {
          X: Math.round(area.x),
          Y: Math.round(area.y),
          Width: Math.round(area.width),
          Height: Math.round(area.height)
        },
        Action: action
      };
    });

    const richMenu = {
      RichMenuId: richMenuId,
      Size: size,
      Name: name,
      ChatBarText: chatBarText,
      Selected: true,
      Areas: mappedAreas
    };

    try {
      const result = await axios.post(`${API_PATH}/super/richmenu/validate`, richMenu);
      alert('Rich Menu 驗證成功');
      if (result.data.success) {
        handleCreateRichMenu(richMenu); // 驗證成功後創建 RichMenu
      }
    } catch (error) {
      console.error('驗證失敗:', error);
    }
  };

  const fetchRichMenuList = async () => {
    const result = await axios.get(`${API_PATH}/super/richmenu/list`);
    setRichMenus(result.data.source.richmenus); // 設置取得的 RichMenu 列表
    const activeMenu = result.data.source.richmenus.find((menu) => menu.selected === true); // 找到選中的 RichMenu
    if (activeMenu) {
      setSelectedRichMenuId(activeMenu.richMenuId); // 設置使用中的 RichMenu ID
    }
  };

  // 刪除 Rich Menu
  const handleDelete = async (richMenuId) => {
    if (window.confirm('你確定要刪除這個 RichMenu 嗎？')) {
      await axios.delete(`${API_PATH}/super/richmenu/${richMenuId}`);
      fetchRichMenuList(); // 刷新列表
    }
  };

  // 創建 Rich Menu
  const handleCreateRichMenu = async (richMenu) => {
    try {
      const result = await axios.post(`${API_PATH}/super/richmenu/create`, richMenu);
      setRichMenuId(result.data.source); // 設置新的 RichMenuId
      if (result.status === 200) {
        console.log(result.data.source);
        handleUploadImage(result.data.source); // 創建成功後上傳圖片
      }
    } catch (error) {
      console.error('創建失敗:', error);
    }
  };

  // 上傳圖片
  const handleUploadImage = async (richMenuId) => {
    if (!imageFile) {
      alert('請上傳圖片');
      return;
    }

    const formData = new FormData();
    formData.append('ImageFile', imageFile);
    formData.append('RichMenuId', richMenuId);

    try {
      const result = await axios.post(`${API_PATH}/super/richmenu/uploadImage`, formData);
      alert('圖片上傳成功');
      if (result.status === 200) {
        handleSetDefaultRichMenu(richMenuId); // 上傳成功後設置為預設
      }
    } catch (error) {
      console.error('圖片上傳失敗:', error);
    }
  };

  // 刪除區域
  const handleDeleteArea = (id) => {
    // 刪除指定ID的區域
    const updatedAreas = areas.filter((area) => area.id !== id);
    setAreas(updatedAreas); // 更新 areas 狀態
  };

  // 設置預設 Rich Menu
  const handleSetDefaultRichMenu = async (richMenuId) => {
    try {
      const result = await axios.get(`${API_PATH}/super/richmenu/setDefault/${richMenuId}`);
      if (result.status === 200) {
        alert('設置預設 Rich Menu 成功');
        fetchRichMenuList();
      }
    } catch (error) {
      console.error('設置預設失敗:', error);
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      drawAreas(); // 只有當 canvasRef.current 存在時才調用 drawAreas
    }
  }, [areas, isDrawing, currentPosition, imagePreview]);

  useEffect(() => {
    fetchRichMenuList();
  }, []);

  return (
    <Box p={4}>
      <div style={{ height: 300, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Rich Menu 列表
        </Typography>
        <DataGridPro
          rows={richMenus}
          columns={columns}
          getRowId={(row) => row.richMenuId}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowClassName={(params) => (params.row.richMenuId === selectedRichMenuId ? 'Mui-selected' : '')}
        />
      </div>
      <Typography variant="h4" gutterBottom>
        Rich Menu 設定
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Rich Menu ID"
            value={richMenuId}
            InputProps={{
              readOnly: true // 設置只讀屬性
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Width"
            type="number"
            value={size.width}
            InputProps={{
              readOnly: true // 設置只讀屬性
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Height"
            type="number"
            value={size.height}
            InputProps={{
              readOnly: true // 設置只讀屬性
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField label="名稱" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <TextField label="Chat Bar Text" value={chatBarText} onChange={(e) => setChatBarText(e.target.value)} fullWidth />
        </Grid>

        {/* 圖片上傳與預覽 */}
        <Grid item xs={12}>
          <Button variant="contained" component="label" fullWidth>
            上傳圖片
            <input type="file" hidden accept="image/*" onChange={handleImageUpload} />
          </Button>
        </Grid>

        {imagePreview && (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <canvas
              ref={canvasRef}
              width={size.width * SCALE_FACTOR}
              height={size.height * SCALE_FACTOR}
              style={{ border: '1px solid black', marginBottom: '20px' }}
              onMouseDown={startDrawing}
              onMouseMove={updateCurrentPosition}
              onMouseUp={finishDrawing}
            />
          </Grid>
        )}

        {/* Editing selected area */}
        {areas.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">區域屬性</Typography>
            </Grid>

            {areas.map((area, index) => (
              <Grid container spacing={2} key={area.id} style={{ marginBottom: '20px' }}>
                {/* 顯示區域編號與 Divider */}
                <Grid item xs={12}>
                  <Typography variant="body1">
                    區域 {String.fromCharCode(97 + index)}: X = {Math.round(area.x)}, Y = {Math.round(area.y)}, Width = {Math.round(area.width)}, Height = {Math.round(area.height)}
                  </Typography>
                  <Divider style={{ marginBottom: '10px' }} />
                </Grid>

                {/* 區域屬性編輯表單 */}
                <Grid item xs={3}>
                  <TextField
                    label="X"
                    type="number"
                    value={area.x}
                    onChange={(e) => handleAreaChange(index, 'x', parseInt(e.target.value, 10))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Y"
                    type="number"
                    value={area.y}
                    onChange={(e) => handleAreaChange(index, 'y', parseInt(e.target.value, 10))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Width"
                    type="number"
                    value={area.width}
                    onChange={(e) => handleAreaChange(index, 'width', parseInt(e.target.value, 10))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Height"
                    type="number"
                    value={area.height}
                    onChange={(e) => handleAreaChange(index, 'height', parseInt(e.target.value, 10))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id={`action-type-label-${index}`}>Action Type</InputLabel>
                    <Select
                      labelId={`action-type-label-${index}`}
                      value={area.action?.type || ''}
                      onChange={(e) => handleAreaChange(index, 'action.type', e.target.value)}
                      label="Action Type"
                      required
                    >
                      <MenuItem value="message">Message</MenuItem>
                      <MenuItem value="postback">Postback</MenuItem>
                      <MenuItem value="uri">Uri</MenuItem>
                      <MenuItem value="datetimepicker">Datetime Picker</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {area.action?.type === 'message' && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Label"
                        value={area.action.label || ''}
                        onChange={(e) => handleAreaChange(index, 'action.label', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Text"
                        value={area.action.text || ''}
                        onChange={(e) => handleAreaChange(index, 'action.text', e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {area.action?.type === 'uri' && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Label"
                        value={area.action.label || ''}
                        onChange={(e) => handleAreaChange(index, 'action.label', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Uri"
                        value={area.action.uri || ''}
                        onChange={(e) => handleAreaChange(index, 'action.uri', e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="AltUri Desktop"
                        value={area.action.altUriDesktop || ''}
                        onChange={(e) => handleAreaChange(index, 'action.altUriDesktop', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {area.action?.type === 'postback' && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Label"
                        value={area.action.label || ''}
                        onChange={(e) => handleAreaChange(index, 'action.label', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Data"
                        value={area.action.data || ''}
                        onChange={(e) => handleAreaChange(index, 'action.data', e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Display Text"
                        value={area.action.displayText || ''}
                        onChange={(e) => handleAreaChange(index, 'action.displayText', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id={`input-option-label-${index}`}>Input Option</InputLabel>
                        <Select
                          labelId={`input-option-label-${index}`}
                          value={area.action.inputOption || ''}
                          onChange={(e) => handleAreaChange(index, 'action.inputOption', e.target.value)}
                          label="Input Option"
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="closeRichMenu">Close Rich Menu</MenuItem>
                          <MenuItem value="openKeyboard">Open Keyboard</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {area.action.inputOption === 'openKeyboard' && (
                      <Grid item xs={6}>
                        <TextField
                          label="Fill In Text"
                          value={area.action.fillInText || ''}
                          onChange={(e) => handleAreaChange(index, 'action.fillInText', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    )}
                  </>
                )}

                {area.action?.type === 'datetimepicker' && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Label"
                        value={area.action.label || ''}
                        onChange={(e) => handleAreaChange(index, 'action.label', e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Data"
                        value={area.action.data || ''}
                        onChange={(e) => handleAreaChange(index, 'action.data', e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id={`mode-label-${index}`}>Mode</InputLabel>
                        <Select
                          labelId={`mode-label-${index}`}
                          value={area.action.mode || ''}
                          onChange={(e) => handleAreaChange(index, 'action.mode', e.target.value)}
                          label="Mode"
                          required
                        >
                          <MenuItem value="date">Date</MenuItem>
                          <MenuItem value="time">Time</MenuItem>
                          <MenuItem value="datetime">Datetime</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Initial"
                        value={area.action.initial || ''}
                        onChange={(e) => handleAreaChange(index, 'action.initial', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Max"
                        value={area.action.max || ''}
                        onChange={(e) => handleAreaChange(index, 'action.max', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Min"
                        value={area.action.min || ''}
                        onChange={(e) => handleAreaChange(index, 'action.min', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {/* 刪除按鈕，放置在區域屬性表單的下方 */}
                <Grid item xs={12} style={{ textAlign: 'right', marginTop: '10px' }}>
                  <Button variant="contained" color="secondary" onClick={() => handleDeleteArea(area.id)}>
                    刪除區域
                  </Button>
                </Grid>
              </Grid>
            ))}
          </>
        )}

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleValidateRichMenu} fullWidth>
            驗證並創建 Rich Menu
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RichMenuForm;
