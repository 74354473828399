export const EmojiList = {
    '5ac2213e040ab15980c9b447':  Array.from({ length: 182 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2213e040ab15980c9b447/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })), // 到182 https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2213e040ab15980c9b447/android/001.png?v=1
    //            https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2213e040ab15980c9b447/android/183.png?v=1
    '5ac21a8c040ab15980c9b43f':  Array.from({ length: 149 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21a8c040ab15980c9b43f/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })), // 到149
    '5ac21c4e031a6752fb806d5b': Array.from({ length: 161 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21c4e031a6752fb806d5b/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })), // 到161
    '5ac21e6c040ab15980c9b444': Array.from({ length: 222 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21e6c040ab15980c9b444/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })), // 到222
    '5ac1bfd5040ab15980c9b435': Array.from({ length: 250 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac1bfd5040ab15980c9b435/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到250
    '5ac22e85040ab15980c9b44f': Array.from({ length: 209 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22e85040ab15980c9b44f/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到209
    '5ac22775040ab15980c9b44c': Array.from({ length: 247 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22775040ab15980c9b44c/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到247
    '5ac2197b040ab15980c9b43d': Array.from({ length: 201 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2197b040ab15980c9b43d/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到201
    '5ac21d59031a6752fb806d5d': Array.from({ length: 212 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21d59031a6752fb806d5d/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到212
    '5ac221ca040ab15980c9b449': Array.from({ length: 167 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac221ca040ab15980c9b449/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到167
    '5ac22bad031a6752fb806d67': Array.from({ length: 214 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22bad031a6752fb806d67/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到214
    '5ac2211e031a6752fb806d61': Array.from({ length: 250 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2211e031a6752fb806d61/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到250
    '5ac21b4f031a6752fb806d59': Array.from({ length: 149 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21b4f031a6752fb806d59/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到149
    '5ac21cce040ab15980c9b443': Array.from({ length: 161 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21cce040ab15980c9b443/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),     // 到161
    '5ac21fda040ab15980c9b446':Array.from({ length: 140 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21fda040ab15980c9b446/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到140
    '5ac22224031a6752fb806d62': Array.from({ length: 251 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22224031a6752fb806d62/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到251
    '5ac22c9e031a6752fb806d68': Array.from({ length: 198 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22c9e031a6752fb806d68/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到198
    '5ac222bf031a6752fb806d64': Array.from({ length: 250 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac222bf031a6752fb806d64/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到250
    '5ac21869040ab15980c9b43b': Array.from({ length: 199 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21869040ab15980c9b43b/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),     // 到199
    '5ac2280f031a6752fb806d65': Array.from({ length: 250 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2280f031a6752fb806d65/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),     // 到250
    '5ac22293031a6752fb806d63':  Array.from({ length: 184 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22293031a6752fb806d63/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到184
    '5ac2173d031a6752fb806d56': Array.from({ length: 240 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2173d031a6752fb806d56/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到240
    '5ac21542031a6752fb806d55': Array.from({ length: 248 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21542031a6752fb806d55/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),     // 到248
    '5ac2216f040ab15980c9b448': Array.from({ length: 188 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2216f040ab15980c9b448/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到188
    '5ac21f52040ab15980c9b445': Array.from({ length: 183 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21f52040ab15980c9b445/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到183
    '5ac21ef5031a6752fb806d5e': Array.from({ length: 150 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21ef5031a6752fb806d5e/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到150
    '5ac223c6040ab15980c9b44a': Array.from({ length: 157 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac223c6040ab15980c9b44a/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到157
    '5ac22b23040ab15980c9b44d': Array.from({ length: 247 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22b23040ab15980c9b44d/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到247
    '5ac22def040ab15980c9b44e': Array.from({ length: 250 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22def040ab15980c9b44e/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到250
    '5ac21c46040ab15980c9b442': Array.from({ length: 221 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21c46040ab15980c9b442/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到221
    '5ac22d62031a6752fb806d69': Array.from({ length: 196 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22d62031a6752fb806d69/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到196
    '5ac21a13031a6752fb806d57': Array.from({ length: 149 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21a13031a6752fb806d57/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到149
    '5ac21a18040ab15980c9b43e': Array.from({ length: 239 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21a18040ab15980c9b43e/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),     // 到239
    '5ac21ba5040ab15980c9b441': Array.from({ length: 161 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21ba5040ab15980c9b441/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到161
    '5ac2264e040ab15980c9b44b': Array.from({ length: 252 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2264e040ab15980c9b44b/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到252
    '5ac2206d031a6752fb806d5f': Array.from({ length: 252 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac2206d031a6752fb806d5f/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到252
    '5ac22a8c031a6752fb806d66': Array.from({ length: 210 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac22a8c031a6752fb806d66/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到210
    '5ac1de17040ab15980c9b438': Array.from({ length: 194 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac1de17040ab15980c9b438/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),// 到194
    '5ac21bf9031a6752fb806d5a': Array.from({ length: 161 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21bf9031a6752fb806d5a/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })), // 到161
    '5ac21cc5031a6752fb806d5c': Array.from({ length: 170 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21cc5031a6752fb806d5c/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),  // 到170
    '5ac21ae3040ab15980c9b440': Array.from({ length: 149 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21ae3040ab15980c9b440/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),   // 到149
    '5ac218e3040ab15980c9b43c': Array.from({ length: 221 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac218e3040ab15980c9b43c/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),    // 到221
    '5ac21184040ab15980c9b43a': Array.from({ length: 234 }, (_, i) => ({
        emojiId: (i + 1).toString().padStart(3, '0'),
        imageUrl: `https://stickershop.line-scdn.net/sticonshop/v1/sticon/5ac21184040ab15980c9b43a/android/${(i + 1).toString().padStart(3, '0')}.png?v=1`,
    })),     // 到234
  };
  
//   // 使用 forEach 迴圈和生成器
//   Object.keys(EmojiList).forEach(productId => {
//     const maxEmojiId = parseInt(productId.slice(-3)); // 根據每個 productId 的規則來自動決定最大 emojiId
//     for (let i = 1; i <= maxEmojiId; i++) {
//       const emojiId = i.toString().padStart(3, '0');  // 將數字轉換為三位數字符串，補0
//       const imageUrl = `https://stickershop.line-scdn.net/sticonshop/v1/sticon/${productId}/android/${emojiId}.png?v=1`;
  
//       EmojiList[productId].push({
//         emojiId: emojiId,
//         imageUrl: imageUrl
//       });
//     }
//   });
  
  console.log(EmojiList);
  