import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link,useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const API_PATH = process.env.REACT_APP_API_PATH;
const groupByCategory = (tags) => {
  return tags.reduce((groups, tag) => {
    const category = tag.category;
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(tag);
    return groups;
  }, {});
};

export default function TagsManage() {  
  const [tags, setTags] = useState([]);
  const groupedTags = groupByCategory(tags);
  const [open, setOpen] = useState(false);
  const [request,setRequest] = useState({
    id: 0,
    tagName: '',
    category: '扭蛋',
  });

  const fetchData = async () => {
      const response = await axios.get(`${API_PATH}/super/tags`);
      if (response.status === 200) { 
        setTags(response.data.source);
      }
  };

  const handleTagForm = async (tag) => {
    if(tag != null) {
      setRequest({
        id:tag.id,
        tagName:tag.tagName,
        category:tag.category
      })
    }else {
      setRequest({
        id:0,
        tagName:"",
        category:"扭蛋"
      })
    }
    setOpen(true);
  };

  const handleTagCloseForm = async () => {
    setOpen(false);
  };

  const handleSubmit = async () => {    
    try {
        const response = await axios.post(`${API_PATH}/super/tag`, request);
        if (response.status === 200) {
          handleTagCloseForm();
          fetchData();
        }
    } catch (error) {
      console.log(error.response.data)
    }                    
  }

  const handleInputChange = (event, propertyName) => {
    const value = event.target.value;
    setRequest((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };



  const handleDeleteSubmit = async (id) => { 
      const response = await axios.delete(`${API_PATH}/super/tag?id=${id}`);
      if (response.status === 200) {
          fetchData();
      }  
  };

  useEffect(() => {
    fetchData();
  }, []); 

  return (
    <>
      <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
        <Grid container spacing={2} style={{marginBottom:'1%'}}>
          <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
            <Typography variant="h2" component="h2">
                標籤管理 
            </Typography>
          </Grid>
          <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
            <Button variant="text" onClick={() =>handleTagForm(null)}>新增標籤</Button>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Stack spacing={2} style={{ width: '100%' }}>
            {Object.keys(groupedTags).map((category) => (
              <div key={category}>
                <h3>{category}</h3> {/* Display category name */}
                <Stack direction="row" spacing={1}>
                  {groupedTags[category].map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.tagName}
                      variant="outlined"
                      onClick={() => handleTagForm(tag)}
                      onDelete={() => handleDeleteSubmit(tag.id)}
                    />
                  ))}
                </Stack>
              </div>
            ))}
          </Stack>
        </Grid>
        </Grid>
      </Box>

      <Dialog open={open} 
          BackdropProps={{
            style: {
              backgroundColor: 'transparent',
            },
          }}
          PaperProps={{
            style: {
              width: '200px',
            },
          }}
      >
        <DialogTitle style={{justifyContent:'center',display:'flex',fontSize:'16px'}}>標籤</DialogTitle>
        <DialogContent>
            <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}>.                  
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="stock-amount-label">標籤專屬類別</InputLabel>
                          <Select
                              labelId="stock-amount-label"
                              id="stock-amount-select"
                              value={request.category}
                              label="標籤專屬類別"
                              onChange={(e) => handleInputChange(e, 'category')}
                          >
                              <MenuItem value=""><em>None</em></MenuItem>
                              <MenuItem value='扭蛋'>扭蛋</MenuItem>
                              <MenuItem value='盲盒'>盲盒</MenuItem>
                              <MenuItem value='福袋'>福袋</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="標籤名稱"
                        InputLabelProps={{
                            shrink: true,
                        }}
                      
                        value={request.tagName}
                        onChange={(e) => handleInputChange(e, 'tagName')}
                        />
                    </Grid>
                </Grid>
            </Box> 
        </DialogContent>
        <DialogActions>
              <Button onClick={handleSubmit}>送出</Button>  
              <Button onClick={handleTagCloseForm}>取消</Button>  
        </DialogActions>
      </Dialog>
    </>
  );
}

