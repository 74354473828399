import React, { useState,useEffect } from 'react';
import { TextField, Button, MenuItem, Grid, Divider, Typography,Dialog, DialogTitle, DialogContent } from '@mui/material';
import { EmojiList } from './Line/EmojiList';
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;

const messageTypes = [
  { value: 'text', label: '文字訊息' },
  { value: 'sticker', label: '貼圖訊息' },
  { value: 'image', label: '圖片訊息' },
  { value: 'video', label: '影片訊息' },
  { value: 'audio', label: '音訊訊息' },
  { value: 'location', label: '位置訊息' },
  { value: 'imagemap', label: 'Imagemap 訊息' },
  { value: 'template_buttons', label: '按鈕模板訊息' },
  { value: 'template_confirm', label: '確認模板訊息' },
  { value: 'flex', label: '彈性訊息（Flex Message）' }
];

// LINE Emoji 清單

const urlRegex = /(https:\/\/stickershop\.line-scdn\.net\/sticonshop\/v1\/sticon\/[a-zA-Z0-9]+\/android\/\d{3}\.png\?v=1)/g;

const BroadcastMessageForm = () => {
    const [emojiCache, setEmojiCache] = useState(EmojiList);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [emojiImgs, setEmojiImgs] = useState([]);
    const [messageType, setMessageType] = useState('text');
    const [textMessage, setTextMessage] = useState('');
    const [renderedMessage, setRenderedMessage] = useState('');
    const [emojis, setEmojis] = useState([]);
    const [isEmojiDialogOpen, setEmojiDialogOpen] = useState(false);
    const [formValues, setFormValues] = useState({});

  // 將 textMessage 中的 URL 替換為圖片
  const renderTextWithImages = () => {
    const parts = renderedMessage.replace(/"/g, '').split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <img
            key={index}
            src={part}
            alt={`img-${index}`}
            style={{ maxWidth: '20px', display: 'inline-block', verticalAlign: 'middle' }}  // 保持在同一行
          />
        );
      } else {
        return (
            <span key={index} style={{ display: 'inline', verticalAlign: 'middle' }}> {/* 保持在同一行 */}
                {part}
            </span>
        );
      }
    });
  };
  
  // 處理表單變更
    const handleInputChange = (field, value) => {
        setFormValues({ ...formValues, [field]: value });
    };

    // 提交表單
    const handleSubmit = async () => {
        let message;
    
        // 根據 messageType 不同生成對應的 message 結構
        switch (messageType) {
            case 'text':
                message = {
                    Type: 'text', // 設定消息類型
                    Text: textMessage, // 處理文字訊息
                    Emojis: emojis.filter((emoji) => emoji.productId && emoji.emojiId).map(emoji => ({
                        Index: emoji.index, // 字符所在的位置
                        ProductID: emoji.productId, // 表情包ID
                        EmojiId: emoji.emojiId // 表情符號ID
                    }))
                };
                break;
            case 'image':
                message = {
                    Type: 'image',
                    // OriginalContentUrl: imageUrl, // 處理圖片訊息
                    // PreviewImageUrl: previewUrl
                };
                break;
            case 'video':
                message = {
                    Type: 'video',
                    // OriginalContentUrl: videoUrl, // 處理視頻訊息
                    // PreviewImageUrl: previewUrl
                };
                break;
            case 'sticker':
                message = {
                    Type: 'sticker',
                    // StickerId: selectedStickerId, // 處理貼圖訊息
                    // PackageId: selectedPackageId
                };
                break;
            // 其他訊息類型可以根據需要擴展
            default:
                console.error('未知的訊息類型');
                return;
        }
    
        try {
            const response = await axios.post(`${API_PATH}/super/oa/broadcast?messageType=${messageType}`, {
                Messages: [message] // 將 message 封裝到 Messages 陣列
            });
    
            alert('推播訊息成功');
        } catch (error) {
            console.error('推播訊息失敗:', error);
            alert('推播訊息失敗');
        }
    };
    
    
    
    // 處理 Emoji 的變更
    const openEmojiDialog = () => {
        setEmojiDialogOpen(true);
      };
    
      // 關閉 Emoji 選擇框
      const closeEmojiDialog = () => {
        setEmojiDialogOpen(false);
      };
    
  // 當選擇 Emoji 時插入到文字並生成 Emoji 資料
  const handleEmojiSelect = (productId, emojiId,img) => {
    // 插入 $ 符號到當前的 textMessage
    const updatedText = `${textMessage} $`;
    setTextMessage(updatedText);
    setEmojiImgs([
        ...emojiImgs,
        img
    ])
    // 生成對應的 Emoji 對象，並將其加入到 emojis 陣列
    setEmojis([
      ...emojis,
      {
        index: updatedText.length - 1, // $ 符號的位置
        productId: productId,
        emojiId: emojiId
      }
    ]);

    // 關閉選擇框
    closeEmojiDialog();
  };

  useEffect(() => {
    // 當 textMessage 改變時檢查是否有符號刪除
    const currentEmojiCount = (textMessage.match(/\$/g) || []).length;

    // 若 $ 符號數量少於 emojis 數量，則需要刪除對應的 emoji
    if (currentEmojiCount < emojis.length) {
        const updatedEmojis = emojis.slice(0, currentEmojiCount); // 保留前面對應數量的 emojis
        setEmojis(updatedEmojis); // 更新 emojis
    }
    // 檢查 textMessage 是否包含 "$"
    if (textMessage.includes('$')) {
      let dollarCount = 0;
      // 使用 replace 方法來替換所有的 "$" 符號
      const replacedMessage = textMessage.split('').map((char) => {
        if (char === '$' && emojiImgs[dollarCount]) {
          const imgTag = `${emojiImgs[dollarCount]}"`;
          dollarCount++;
          return imgTag;  // 返回圖片標籤
        }
        return char;  // 返回非 "$" 的字符
      }).join('');
      console.log(replacedMessage);
      setRenderedMessage(replacedMessage);  // 更新渲染內容
    } else {
      setRenderedMessage(textMessage);  // 無 "$" 時顯示原始訊息
    }
  }, [textMessage, emojiImgs]);


  // 根據訊息類型顯示不同的客製化表單
  const renderMessageForm = () => {
    switch (messageType) {
      case 'text':
        return (
            <>
          <Grid container spacing={2} style={{ padding: '20px' }}>
            {/* 文字訊息輸入框 */}
            <Grid item xs={12}>
              <TextField
                label="訊息文字"
                value={textMessage}
                onChange={(e) => setTextMessage(e.target.value)}
                multiline
                fullWidth
                rows={4}
              />
                    {/* 顯示處理後的文本 */}
            <div style={{ marginTop: '20px' }}>
                {renderTextWithImages()}
            </div>
            </Grid>

            {/* Emoji 選擇按鈕 */}
            <Grid item xs={12} style={{ justifyItems: 'center' }}>
              <Button variant="text" onClick={openEmojiDialog} fullWidth>
                插入 Emoji
              </Button>
            </Grid>

            {/* Emoji 選擇對話框 */}
            <Dialog open={isEmojiDialogOpen} onClose={closeEmojiDialog}>
              <DialogTitle>選擇 Emoji</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                    {/* {Object.keys(emojiCache).map((productId) => (
                        <Grid item xs={12} key={productId}>
                        <Typography variant="h6">Product ID: {productId}</Typography>
                        <Grid container spacing={1}>
                            {emojiCache[productId].map((emoji) => (
                            <Grid item xs={2} key={emoji.emojiId}>
                                <Button
                                variant="text" // 这里移除了边框
                                onClick={() => handleEmojiSelect(productId, emoji.emojiId,emoji.imageUrl)}
                                fullWidth
                                style={{ padding: '0', minWidth: 'auto' }} // 调整按钮的大小
                                >
                                <img
                                    src={emoji.imageUrl}
                                    alt={`emoji-${emoji.emojiId}`}
                                    style={{ width: '20px', height: '20px' }} // 图像大小
                                />
                                </Button>
                            </Grid>
                            ))}
                        </Grid>
                        </Grid>
                    ))} */}
                        {/* 顯示所有的 productId 列表 */}
                        {Object.keys(emojiCache).map((productId) => (
                        <Grid item xs={12} key={productId}>
                            <Button variant="outlined" onClick={() => setSelectedProductId(productId)}>
                            {`Product ID: ${productId}`}
                            </Button>
                        </Grid>
                        ))}

                        {/* 點擊後顯示對應的 emoji 列表 */}
                        {selectedProductId && (
                        <Grid container spacing={1}>
                            <Typography variant="h6">{`Product ID: ${selectedProductId}`}</Typography>
                            {emojiCache[selectedProductId].map((emoji) => (
                            <Grid item xs={2} key={emoji.emojiId}>
                                <Button
                                variant="text"
                                onClick={() => handleEmojiSelect(selectedProductId, emoji.emojiId, emoji.imageUrl)}
                                style={{ padding: '0', minWidth: 'auto' }}
                                >
                                <img
                                    src={emoji.imageUrl}
                                    alt={`emoji-${emoji.emojiId}`}
                                    style={{ width: '20px', height: '20px' }}
                                />
                                </Button>
                            </Grid>
                            ))}
                        </Grid>
                        )}
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>
          </>
        );
      case 'sticker':
        return (
          <>
            <TextField
              label="Package ID"
              value={formValues.packageId || ''}
              onChange={(e) => handleInputChange('packageId', e.target.value)}
              fullWidth
            />
            <TextField
              label="Sticker ID"
              value={formValues.stickerId || ''}
              onChange={(e) => handleInputChange('stickerId', e.target.value)}
              fullWidth
            />
          </>
        );
      case 'image':
        return (
          <>
            <TextField
              label="Original Content URL"
              value={formValues.originalContentUrl || ''}
              onChange={(e) => handleInputChange('originalContentUrl', e.target.value)}
              fullWidth
            />
            <TextField
              label="Preview Image URL"
              value={formValues.previewImageUrl || ''}
              onChange={(e) => handleInputChange('previewImageUrl', e.target.value)}
              fullWidth
            />
          </>
        );
      case 'video':
        return (
          <>
            <TextField
              label="Original Content URL"
              value={formValues.originalContentUrl || ''}
              onChange={(e) => handleInputChange('originalContentUrl', e.target.value)}
              fullWidth
            />
            <TextField
              label="Preview Image URL"
              value={formValues.previewImageUrl || ''}
              onChange={(e) => handleInputChange('previewImageUrl', e.target.value)}
              fullWidth
            />
            <TextField
              label="Tracking ID"
              value={formValues.trackingId || ''}
              onChange={(e) => handleInputChange('trackingId', e.target.value)}
              fullWidth
            />
          </>
        );
      case 'audio':
        return (
          <>
            <TextField
              label="Original Content URL"
              value={formValues.originalContentUrl || ''}
              onChange={(e) => handleInputChange('originalContentUrl', e.target.value)}
              fullWidth
            />
            <TextField
              label="Duration (ms)"
              value={formValues.duration || ''}
              onChange={(e) => handleInputChange('duration', e.target.value)}
              fullWidth
            />
          </>
        );
      case 'location':
        return (
          <>
            <TextField
              label="標題"
              value={formValues.title || ''}
              onChange={(e) => handleInputChange('title', e.target.value)}
              fullWidth
            />
            <TextField
              label="地址"
              value={formValues.address || ''}
              onChange={(e) => handleInputChange('address', e.target.value)}
              fullWidth
            />
            <TextField
              label="緯度"
              value={formValues.latitude || ''}
              onChange={(e) => handleInputChange('latitude', e.target.value)}
              fullWidth
            />
            <TextField
              label="經度"
              value={formValues.longitude || ''}
              onChange={(e) => handleInputChange('longitude', e.target.value)}
              fullWidth
            />
          </>
        );
      case 'imagemap':
        return (
          <>
            <TextField
              label="Base URL"
              value={formValues.baseUrl || ''}
              onChange={(e) => handleInputChange('baseUrl', e.target.value)}
              fullWidth
            />
            <TextField
              label="Alt Text"
              value={formValues.altText || ''}
              onChange={(e) => handleInputChange('altText', e.target.value)}
              fullWidth
            />
            {/* 此處可以擴展動作的設定 */}
          </>
        );
      case 'template_buttons':
        return (
          <>
            <TextField
              label="縮圖 URL"
              value={formValues.thumbnailImageUrl || ''}
              onChange={(e) => handleInputChange('thumbnailImageUrl', e.target.value)}
              fullWidth
            />
            <TextField
              label="標題"
              value={formValues.title || ''}
              onChange={(e) => handleInputChange('title', e.target.value)}
              fullWidth
            />
            <TextField
              label="內容"
              value={formValues.text || ''}
              onChange={(e) => handleInputChange('text', e.target.value)}
              fullWidth
            />
            {/* 此處可以加入按鈕的動作設定 */}
          </>
        );
      case 'template_confirm':
        return (
          <>
            <TextField
              label="確認文字"
              value={formValues.text || ''}
              onChange={(e) => handleInputChange('text', e.target.value)}
              fullWidth
            />
            {/* 此處可以加入按鈕的動作設定 */}
          </>
        );
      case 'flex':
        return (
          <>
            <TextField
              label="Flex JSON 內容"
              value={formValues.flexContent || ''}
              onChange={(e) => handleInputChange('flexContent', e.target.value)}
              fullWidth
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {/* 訊息類型選擇器 */}
      <Grid item xs={12}>
        <TextField
          select
          label="訊息類型"
          value={messageType}
          onChange={(e) => setMessageType(e.target.value)}
          fullWidth
        >
          {messageTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* 根據訊息類型動態顯示表單 */}
      <Grid item xs={12}>
        {renderMessageForm()}
      </Grid>

      {/* 提交按鈕 */}
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
          送出推播訊息
        </Button>
      </Grid>
    </Grid>
  );
};

export default BroadcastMessageForm;
